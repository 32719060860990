<template>
  <td>
    <v-layout column align-end>
      <v-layout align-center>
        <IconButton
          icon="mdi-link"
          bgcolor="transparent"
          :color="chained ? 'primary' : 'icons'"
          @clicked="$emit('pair', item)"
        ></IconButton>
        <IconButton
          @clicked="$emit('edit', item)"
          icon="mdi-pencil"
          color="primary"
          bgcolor="transparent"
        ></IconButton>
        <IconButton
          @clicked="$emit('remove', item)"
          icon="mdi-trash-can-outline"
          color="error"
          bgcolor="transparent"
        ></IconButton>
      </v-layout>
    </v-layout>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
    value: {},
  },
  components: {
    IconButton: () => import("@/components/buttons/IconButton"),
  },
  computed: {
    defaultValue() {
      if (this.value) {
        return this.value;
      } else if (this.item == undefined || this.header == undefined) {
        return "";
      } else {
        return this.item[this.header.value];
      }
    },
    chained() {
      return this.item.chained;
    }
  },
};
</script>
